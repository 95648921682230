import Rewind10SecondsBackwardIcon from '@wearemojo/icons/Outline/VideoAudioSound/Rewind10SecondsBackIcon';
import Rewind10SecondsForwardIcon from '@wearemojo/icons/Outline/VideoAudioSound/Rewind10SecondsForwardIcon';
import { BrandColor, SCREEN_MAX_WIDTH } from '@wearemojo/ui-constants';
import { Image } from 'expo-image';
import { ElementRef, useRef } from 'react';
import {
	ActivityIndicator,
	ImageBackground,
	Platform,
	View,
} from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BottomSheet from './BottomSheet';
import Chip from './Chip';
import ErrorBox from './ErrorBox';
import PlaybackSpeedOptionsSheet from './PlaybackSpeedOptionsSheet';
import PressableOpacity from './PressableOpacity';
import Text from './Text';
import { AudioPlayerChildrenProps, formatTime } from './utils/audio';

const fallbackImage = require('./assets/audio-player-fallback-image.png');

const AudioPlayerView = ({
	playbackRate,
	slider,
	currentTimeSec,
	totalTimeSec,
	jumpPositionBy,
	setPlaybackRate,
	hasError,
	error,
	status,
	playControl,
	audioImage,
}: AudioPlayerChildrenProps) => {
	const {
		styles,
		theme: { iconSize },
	} = useStyles(stylesheet);
	const playbackSpeedSheetRef = useRef<ElementRef<typeof BottomSheet>>(null);

	return (
		<View style={styles.root}>
			<View style={styles.centerPart}>
				<ImageBackground
					source={fallbackImage}
					style={styles.image}
					imageStyle={styles.fallbackImage}
				>
					{audioImage?.uri && (
						<Image
							source={{ uri: audioImage.uri }}
							style={styles.overlayImage}
							alt="audio player"
						/>
					)}
				</ImageBackground>
			</View>

			<View style={styles.bottomPart}>
				<View>
					<PressableOpacity
						style={styles.chipWrap}
						onPress={() => {
							playbackSpeedSheetRef.current?.present();
						}}
					>
						<Chip
							title={`${playbackRate}x`}
							background="neutral_700"
							textColor="content_neutral"
						/>
					</PressableOpacity>
					{slider}
					<View style={styles.timeRangesWrap}>
						<Text themeColor="content_neutral" variant="heading_xxs">
							{formatTime(currentTimeSec)}
						</Text>
						<Text themeColor="content_neutral" variant="heading_xxs">
							{formatTime(totalTimeSec - currentTimeSec)}
						</Text>
					</View>
				</View>

				<View style={styles.controls}>
					<View style={styles.noIconWrap} />
					<PressableOpacity
						style={styles.iconWrap}
						onPress={() => {
							jumpPositionBy?.(-10);
						}}
					>
						<Rewind10SecondsBackwardIcon
							fill={BrandColor.white}
							size={iconSize.md}
						/>
					</PressableOpacity>
					{!status.isLoading ? (
						playControl
					) : (
						<ActivityIndicator size="small" color={BrandColor.white} />
					)}
					<PressableOpacity
						style={styles.iconWrap}
						onPress={() => {
							jumpPositionBy?.(10);
						}}
					>
						<Rewind10SecondsForwardIcon
							fill={BrandColor.white}
							size={iconSize.md}
						/>
					</PressableOpacity>
					<View style={styles.noIconWrap} />
					<PlaybackSpeedOptionsSheet
						playbackRate={playbackRate}
						setPlaybackRate={setPlaybackRate}
						ref={playbackSpeedSheetRef}
					/>
				</View>
				{hasError && (
					<ErrorBox label="Audio Error" message={String(error)} fillParent />
				)}
			</View>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius }) => ({
	root: {
		flex: 1,
		maxWidth: SCREEN_MAX_WIDTH,
		alignSelf: 'center',
		width: '100%',
	},
	centerPart: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	bottomPart: {
		gap: spacing.xl2,
		flexDirection: 'column',
		minHeight: 'auto', // needed for web as a min-height 0 is added to all divs
	},
	fallbackImage: {
		resizeMode: 'contain',
	},
	image: {
		height: 127,
		width: 127,
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	},
	overlayImage: {
		height: '92%',
		width: '92%',
		resizeMode: 'cover',
		borderRadius: radius.full,
	},
	chipWrap: {
		alignSelf: 'center',
		marginVertical: spacing.xs,
	},
	timeRangesWrap: {
		justifyContent: 'space-between',
		flexDirection: 'row',
		flex: 1,
	},
	controls: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		minHeight: 'auto', // needed for web as a min-height 0 is added to all divs
		marginBottom: Platform.OS === 'web' ? 20 : 0,
	},
	iconWrap: {
		width: 48,
		height: 48,
		borderRadius: radius.xl,
		alignItems: 'center',
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: `${BrandColor.neutral_200}4D`,
	},
	noIconWrap: {
		width: 48,
		height: 48,
	},
}));

export default AudioPlayerView;
